import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ModalStackManager from "./component/modal/context/ModalStackManager";
import ThemeProvider from "./provider/ThemeProvider";
import Global from "./style/Global";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider>
        <ModalStackManager>
          <ThemeProvider>
            <Global />
            <App />
          </ThemeProvider>
        </ModalStackManager>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
