import styled from "@emotion/styled";

interface FileBoxImageProps {
  icon?: React.ReactNode;
}

export const FileBoxIcon = ({ icon }: FileBoxImageProps) => {
  return <FileBoxImageWrapper>{icon}</FileBoxImageWrapper>;
};

const FileBoxImageWrapper = styled.div`
  background-color: #898787;
  flex: 1 0 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
