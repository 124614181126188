import { Blank, Box, BoxEm, BoxP, List } from "../../index.styled";

export const LogList = ({ logs }: any) => {
  return (
    <>
      {logs.map((log: any, index: any) => {
        return (
          <>
            <List active={index === 0}>
              <Box>
                <BoxEm>{log.createdAt}</BoxEm>
                <BoxP>
                  {log.description} {log.name}
                </BoxP>
              </Box>
            </List>
            <Blank />
          </>
        );
      })}
    </>
  );
};
