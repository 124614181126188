import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BASE_URL } from "../instance/axios";
import { useDownLoadAttachMentProps } from "../pages/dashboard/types";

export const QUERY_KEY = "/download";

const fetch = (attachmentId: number) =>
  axios.get(`${BASE_URL}/ticket/attachment/${attachmentId}`, {
    responseType: "blob",
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyIqIl0sInVzZXIiOnsiaWQiOjEzLCJlbWFpbCI6IjE1cXVldWVAc2l6bC5jby5rciIsInNpdGVJZCI6MSwibmFtZSI6IlRlc3QifSwiaWF0IjoxNzAzNTgwNzQ1fQ.kituzvA28cuNZL03S8FmR_wVVR6SkYKLayFgR276Vpo",
    },
  });

const useDownLoadAttachMentQuery = ({
  attachmentId,
}: useDownLoadAttachMentProps) => {
  return useQuery({
    queryKey: [QUERY_KEY, attachmentId],
    queryFn: () => fetch(attachmentId),
    enabled: !!attachmentId,
  });
};

export default useDownLoadAttachMentQuery;
