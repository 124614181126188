import { StepStatusAndShippingInfoProps } from "../pages/dashboard/types";

export const getStepStausActiveNum = ({
  stepStatus,
  shippingInfo,
  isStagesDonMessageSent
}: StepStatusAndShippingInfoProps) => {
  if (stepStatus === "WAITING") return 1;
  if (stepStatus === "IN_PROGRESS" || stepStatus === "PROCESSING" || stepStatus === "PENDING" || stepStatus === "PROCESS_PENDING") return 2;
  if (stepStatus === "DONE" && isStagesDonMessageSent === true && shippingInfo == null) return 3;
  else if (stepStatus === "DONE" && isStagesDonMessageSent === false) return 2;
  else if (stepStatus === "DONE" && isStagesDonMessageSent === true  && shippingInfo !== null) return 4;
  return 0;
};
