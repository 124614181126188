import styled from "@emotion/styled";
import { pdfjs } from "react-pdf";
import Dashboards from "./pages/dashboard";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  return (
    <Main>
      <Dashboards />
    </Main>
  );
}

const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  /* height: 100vh; */

  background-color: #0b0b19;
  display: flex;
  flex-direction: column;
`;

export default App;
