import styled from "@emotion/styled";
import { useState } from "react";
import { IoCloseOutline, IoDownloadOutline } from "react-icons/io5";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export function PreviewForm({ file, onClose, filename }: any) {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onDownLoad = () => {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(file);
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <IoDownloadOutline
          onClick={onDownLoad}
          size={"1.4rem"}
          style={{ cursor: "pointer" }}
        />
        <IoCloseOutline
          size={"1.4rem"}
          onClick={onClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <PreviewWrapper>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              scale={0.6}
            />
          ))}
        </Document>
      </PreviewWrapper>
    </>
  );
}

const PreviewWrapper = styled.div`
  height: 500px;
  overflow: scroll;
`;
