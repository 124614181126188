import useGetTicketLogsQuery from "../../api/useGetTicketLogsQuery";
import useGetTicketQuery from "../../api/useGetTicketQuery";
import { getStepStausActiveNum } from "../../utils/getStepStatusActiveNum";
import timeUtil from "../../utils/timeUtil";
import { LogList } from "./components/List/LogList";

import { useState } from "react";
import useGetAttachMentQuery from "../../api/useGetAttachMentQuery";
import { useModal } from "../../component/modal/context/ModalStackManager";
import { FileModalForm } from "../../component/modal/file/FileModalForm";
import { makeLogListAndReverse } from "../../utils/makeLogList";
import {
  ActiveBar,
  ComTit,
  ComTitEm,
  Contain,
  OfferSheetButton,
  ProgressBar,
  TopProgress,
  TopProgressLi,
  TopProgressUl,
  Wrap,
} from "./index.styled";

const STEP_STATUS_KOR = [
  "접수 완료",
  "정비 대기",
  "정비 시작",
  "정비 완료",
  "출고 완료",
];

const Dashboard = () => {
  const params = new URLSearchParams(window.location.search);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const ticketId = Number(params.get("ticketId"));
  const { data: files } = useGetAttachMentQuery({ ticketId: ticketId! });
  const { openModal, closeModal } = useModal();

  const { data: ticketData } = useGetTicketQuery({
    ticketId: ticketId,
  });

  const { data: ticketLog } = useGetTicketLogsQuery({
    ticketId: ticketId,
  });

  const activeNumber = getStepStausActiveNum({
    stepStatus: ticketData?.stepStatus,
    shippingInfo: ticketData?.shippingInfo,
    isStagesDonMessageSent: ticketData?.isStagesDoneMessageSent,
  });

  const advisorLog = {
    createdAt: timeUtil(ticketData?.createdAt!),
    description: `접수 완료 ( 담당 서비스 어드바이저 : ${ticketData?.createUser.name})`,
  };
  const repairWaitLog = {
    createdAt: timeUtil(ticketData?.createdAt!),
    description: `정비 대기`,
  };
  const logList = [
    ...makeLogListAndReverse({ ticketData: ticketData }),
    repairWaitLog,
    advisorLog,
  ];

  const checkOfferSheet = () => {
    openModal(
      <FileModalForm ticketId={ticketId} onClose={() => closeModal({})} />,
      "",
      ""
    );
  };
  return (
    <Wrap>
      <TopProgress>
        <TopProgressUl>
          {STEP_STATUS_KOR.map((name, idx) => {
            return (
              <TopProgressLi on={idx === activeNumber}>{name}</TopProgressLi>
            );
          })}
        </TopProgressUl>
        <ProgressBar>
          <ActiveBar activeNumber={activeNumber}></ActiveBar>
        </ProgressBar>
      </TopProgress>
      <ComTit>
        고객님의 차량은 현재 <br />
        <ComTitEm>{STEP_STATUS_KOR[activeNumber]} </ComTitEm>
        상태입니다.
      </ComTit>
      <Contain isOpen={isFileOpen}>
        <LogList
          logs={logList}
          STEP_STATUS_KOR={STEP_STATUS_KOR[activeNumber]}
        />
      </Contain>
      {files?.rows && files?.rows.length > 0 && (
        <OfferSheetButton onClick={checkOfferSheet}>
          {"첨부파일 확인"}
        </OfferSheetButton>
      )}
    </Wrap>
  );
};

export default Dashboard;
