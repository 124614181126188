import { useEffect, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import useDownLoadAttachMentQuery from "../../../api/useDownLoadAttachMentQuery";
import useGetAttachMentQuery from "../../../api/useGetAttachMentQuery";
import { useModal } from "../context/ModalStackManager";
import { PreviewForm } from "../preview/PreviewForm";
import { FileBox } from "./FileBox";
import { FileBoxButton } from "./FileBoxButton";
import { FileBoxIcon } from "./FileBoxImage";
import { FileBoxInform } from "./FileBoxInform";
import { FileBoxListWrapper } from "./FileBoxListWrapper";
import { FileButton } from "./FileButton";
import { FileDropZone } from "./FileDropZone";
import { FileDropZoneBox } from "./FileDropZoneBox";
import { FileDropZoneBoxTitle } from "./FileDropZoneBoxTitle";
import { FileDropZoneDescription } from "./FileDropZoneDescription";
import { FileDropZoneTitle } from "./FileDropZoneTitle";
import { FileMain } from "./FileMain";

const File = Object.assign(FileMain, {
  DropZoneBoxTitle: FileDropZoneBoxTitle,
  DropZoneBox: FileDropZoneBox,
  DropZone: FileDropZone,
  DropZoneTitle: FileDropZoneTitle,
  DropZoneDescription: FileDropZoneDescription,
  Box: FileBox,
  BoxButton: FileBoxButton,
  BoxIcon: FileBoxIcon,
  BoxInform: FileBoxInform,
  BoxWrapper: FileBoxListWrapper,
  SaveButton: FileButton,
});

interface FileModalFormProps {
  onClose: () => void;
  ticketId?: number;
}

interface FileProps {
  createdAt: string;
  fileName: string;
  filePath: string;
  id: number;
  modifiedAt: string;
  ticketId: number;
}

export const FileModalForm = ({ onClose, ticketId }: FileModalFormProps) => {
  const { openModal, closeModal } = useModal();
  const [clickedFile, setClickedFile] = useState<FileProps>();
  const { data: files } = useGetAttachMentQuery({ ticketId: ticketId! });
  const { data: downloadedFile } = useDownLoadAttachMentQuery({
    attachmentId: clickedFile?.id!,
  });

  const downloadFile = (data: string, filename: string, mime: string) => {
    const blob = new Blob([data], { type: mime });
    const url = window.URL.createObjectURL(blob);

    if (mime === "application/pdf") {
      openModal(
        <PreviewForm
          file={blob}
          filename={filename}
          onClose={() => closeModal({})}
        />,
        "",
        ""
      );
    } else {
      const newTab = window.open() as Window;
      newTab.document.body.innerHTML = `<img src="${url}" alt="${filename}" style="width: 100%; height: 100%; object-fit: contain;">`;
      // 이미지를 클릭하면 다운로드 시작
      newTab.document.body.onclick = () => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        newTab.document.body.appendChild(link);
        link.click();
        newTab.document.body.removeChild(link);
      };
    }
  };

  const handleDownloadFile = (file: any) => {
    setClickedFile(file);
  };

  useEffect(() => {
    if (downloadedFile) {
      downloadFile(
        downloadedFile.data,
        clickedFile?.fileName as string,
        downloadedFile.headers?.["content-type"]
      );
      setClickedFile(undefined);
    }
  }, [downloadedFile]);

  return (
    <File>
      <File.DropZoneBox>
        <File.DropZoneBoxTitle align="left">
          {"첨부파일 목록"}
          <IoCloseOutline onClick={onClose} style={{ cursor: "pointer" }} />
        </File.DropZoneBoxTitle>
      </File.DropZoneBox>
      <File.BoxWrapper>
        {files?.rows?.map((file) => {
          return (
            <File.Box>
              <File.BoxInform>
                <p>{file.fileName}</p>
              </File.BoxInform>
              <File.BoxButton
                icon={<IoMdEye size={25} color="#FFFFFF" />}
                onClick={() => handleDownloadFile(file)}
              />
            </File.Box>
          );
        })}
      </File.BoxWrapper>
    </File>
  );
};
