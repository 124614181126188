import { AttachmentListResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import BaseInstance from "../instance/axios";
import { UseGetTicketQueryProps } from "../pages/dashboard/types";

export const QUERY_KEY = "/attachments";

const fetch = (ticketId: number) =>
  BaseInstance.attachmentsGet(
    {},
    {
      params: {
        query: {
          ticketId: ticketId,
        },
      },
    }
  );

const useGetAttachMentQuery = ({ ticketId }: UseGetTicketQueryProps) => {
  return useQuery({
    queryKey: [QUERY_KEY, ticketId],
    queryFn: () => fetch(ticketId),
    enabled: !!ticketId,
    select: (res) => res.data as AttachmentListResponse,
  });
};

export default useGetAttachMentQuery;
