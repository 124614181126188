import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrap = styled.div`
  max-width: clamp(380px, 5em, 100%);
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  padding: 1rem;
  background-color: #000000;
`;

export const TopProgress = styled.div`
  background: url("/images/tit-ico.png") no-repeat center top;
  background-size: 8lvh;
  margin: 1rem 0;
`;

export const TopProgressUl = styled.ul`
  background-size: contain;
  display: flex;
  height: 6.25rem;
  gap: 1.5625rem;
`;

export const TopProgressLi = styled.li<{ on?: Boolean }>`
  font-size: var(--font-size-base);
  font-weight: 700;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 10%;
  opacity: 30%;
  color: var(--tit-color);

  ${({ on }) =>
    on &&
    css`
      font-size: var(--font-size-lg-1);
      color: var(--tit-color);
      flex: 0 0 30%;
      opacity: 1;

      & ~ li {
        color: var(--color);
      }
    `}
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 1rem;
  background-color: var(--color);
  border-radius: var(--radius-10);
  margin-top: 1.25rem;
`;

export const ActiveBar = styled.div<{ activeNumber: number }>`
  background-color: var(--main-color);
  width: ${({ activeNumber }) =>
    activeNumber === 1
      ? "40%"
      : activeNumber === 2
      ? "60%"
      : activeNumber === 3
      ? "80%"
      : "100%"};
  height: 100%;
  border-radius: var(--radius-10);
  background-image: url("/images/bar-ico.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  animation: progress 1.5s 1s forwards;
  transition: all ease 1s;
`;

export const ComTit = styled.div`
  text-align: center;
  font-size: var(--font-size-xl);
  font-weight: 500;
  margin-top: 1.625rem;
`;

export const ComTitEm = styled.em`
  color: var(--main-color);
  font-style: normal;
`;

export const Contain = styled.div<{ isOpen: boolean }>`
  overflow-y: ${(props) => (props.isOpen ? "hidden" : "auto")};
  position: relative;
  margin-top: 1.625rem;
  height: 18.5rem;
`;

export const Blank = styled.span`
  width: 0.125rem;
  height: 0.8125rem;
  position: relative;
  display: block;
`;

export const ContainSpan = styled.span`
  width: 0.125rem;
  height: 0.8125rem;
  position: relative;
  display: block;
`;

export const List = styled.div<{ active?: Boolean }>`
  position: relative;
  padding-left: 1.25rem;
  opacity: 0.3;
  z-index: 0;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    background-color: var(--main-color);
    width: 0.3125rem;
    height: 100%;
    border-radius: 0.3125rem;
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    -ms-border-radius: 0.3125rem;
    -o-border-radius: 0.3125rem;
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
    `}
`;

export const Box = styled.div`
  background-color: var(--color);
  border-radius: var(--radius-5);
  color: var(--cont-text-color);
  padding: 0.8125rem;
`;

export const BoxEm = styled.em`
  color: var(--gray-color);
  font-style: normal;
`;

export const BoxP = styled.p`
  margin-top: 0.1875rem;
  font-weight: 500;
`;

export const ListA = styled.a`
  border: 0.125rem solid var(--btn-color);
  color: var(--btn-color);
  border-radius: var(--radius-5);
  padding: 0.8125rem;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
`;

export const OfferSheetButton = styled.button`
  border: 0.125rem solid var(--btn-color);
  color: var(--btn-color);
  border-radius: var(--radius-5);
  -webkit-border-radius: var(--radius-5);
  -moz-border-radius: var(--radius-5);
  -ms-border-radius: var(--radius-5);
  -o-border-radius: var(--radius-5);
  padding: 0.8125rem;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;

  width: 100%;
`;
