import {
  MergeTicketDataAndLogsProps,
  TicketDataType,
  TicketLogListProps,
} from "../pages/dashboard/types";
import timeUtil from "./timeUtil";

interface getLogDescriptionProps {
  log: any;
  index: number;
  ticketData: TicketDataType;
}

const getLogDescription = ({
  log,
  index,
  ticketData,
}: getLogDescriptionProps) => {
  const { oldStatus, newStatus } = log.additional || {};

  if (oldStatus === "WAITING" && newStatus === "IN_PROGRESS")
    return "정비 시작";
  if (oldStatus === "DONE" && newStatus === "IN_PROGRESS") return "정비 시작";
  if (
    newStatus === "DONE" &&
    oldStatus === "DONE" &&
    ticketData?.isStagesDoneMessageSent === true
  )
    return "정비 완료";
  if (
    newStatus === "DONE" &&
    oldStatus === "IN_PROGRESS" &&
    ticketData?.isStagesDoneMessageSent === true
  )
    return "정비 완료";
  if (newStatus === "DONE" && oldStatus === "DONE") return "출고 완료";
  return "";
};

export const makeLogListAndReverse = ({
  ticketData,
}: MergeTicketDataAndLogsProps): TicketLogListProps[] => {
  const logDescription = ticketData?.stagesInfo?.[0].logs
    ?.map((log, index) =>
      getLogDescription({ log: log, index: index, ticketData })
    )
    .filter((description) => description.trim() !== "");

  // if (ticketData?.isStagesDoneMessageSent === true) {
  //   logDescription?.push("정비 완료")
  // }
  if (ticketData?.shippingInfo?.shippingInfoTime) {
    logDescription?.push("출고 완료");
  }

  return (
    logDescription
      ?.map((log, index) => ({
        createdAt: timeUtil(
          log === "정비 완료"
            ? ticketData?.isStagesDoneMessageSentTime ??
                ticketData?.stagesInfo?.[0].logs?.[index].createdAt!
            : log === "출고 완료"
            ? ticketData?.shippingInfo?.shippingInfoTime!
            : ticketData?.stagesInfo?.[0].logs?.[index].createdAt!
        ),
        description: log,
        name:
          index === 0
            ? `( 담당 테크니션 : ${ticketData?.stagesInfo?.[0].logs?.[index].user?.name})`
            : undefined,
      }))
      .reverse() ?? []
  );
};
