import { TicketLogResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import BaseInstance from "../instance/axios";
import {
  TicketLogCreateUserProps,
  UseGetTicketQueryProps,
} from "../pages/dashboard/types";

export const QUERY_KEY = "/ticketLog";

const fetch = (ticketId: number) =>
  BaseInstance.ticketLogsTicketLogIdGet(ticketId, {
    params: {
      populate: ["createUser"],
    },
  });

const useGetTicketLogsQuery = ({ ticketId }: UseGetTicketQueryProps) => {
  return useQuery({
    queryKey: [QUERY_KEY, ticketId],
    queryFn: () => fetch(ticketId),
    enabled: !!ticketId,
    select: (res) =>
      res.data as TicketLogResponse & {
        createUser: TicketLogCreateUserProps;
      },
  });
};

export default useGetTicketLogsQuery;
