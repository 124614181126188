import { useQuery } from "@tanstack/react-query";
import BaseInstance from "../instance/axios";
import {
  TicketDataType,
  UseGetTicketQueryProps,
} from "../pages/dashboard/types";

export const QUERY_KEY = "/tickets";

const fetch = (ticketId: number) =>
  BaseInstance.ticketsTicketIdGet(ticketId, {
    params: {
      populate: ["stagesInfo", "createUser"],
    },
  });

const useGetTicketQuery = ({ ticketId }: UseGetTicketQueryProps) => {
  return useQuery({
    queryKey: [QUERY_KEY, ticketId],
    queryFn: () => fetch(ticketId),
    enabled: !!ticketId,
    select: (res) => res.data as TicketDataType,
  });
};

export default useGetTicketQuery;
