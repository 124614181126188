import styled from "@emotion/styled";

interface FileBoxInformProps {
  children: React.ReactNode;
}
export const FileBoxInform = ({ children }: FileBoxInformProps) => {
  return <FileBoxInformWrapper>{children}</FileBoxInformWrapper>;
};

const FileBoxInformWrapper = styled.div`
  padding: 20px 10px;
  flex: 1 0 65%;
  height: 100%;
  color: #ffffff;
`;
