import {
  Configuration,
  DefaultApi,
} from "@sizlcorp/mbk-api-document/dist/models";
import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://mbk.sizl.co.kr/api";
export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyIqIl0sInVzZXIiOnsiaWQiOjEzLCJlbWFpbCI6IjE1cXVldWVAc2l6bC5jby5rciIsInNpdGVJZCI6MSwibmFtZSI6IlRlc3QifSwiaWF0IjoxNzAzNTgwNzQ1fQ.kituzvA28cuNZL03S8FmR_wVVR6SkYKLayFgR276Vpo",
  },
  timeout: 1000 * 60 * 60 * 24 * 7,
});
export const baseAxios = (url?: string) => {
  return new DefaultApi(
    {
      apiKey:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyIqIl0sInVzZXIiOnsiaWQiOjEzLCJlbWFpbCI6IjE1cXVldWVAc2l6bC5jby5rciIsInNpdGVJZCI6MSwibmFtZSI6IlRlc3QifSwiaWF0IjoxNzAzNTgwNzQ1fQ.kituzvA28cuNZL03S8FmR_wVVR6SkYKLayFgR276Vpo",
    } as Configuration,
    url,
    axiosInstance
  );
};
const BaseInstance = baseAxios(BASE_URL);
export default BaseInstance;
